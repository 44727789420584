import React, {useEffect, useState} from 'react';
import {Layer, Sprite, Stage} from 'react-konva';
import './CharacterCustomizationPage.css';
import HomeButton from "../../Components/HomeButton/HomeButton";

const createImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new window.Image();
    img.onload = () => resolve(img);
    img.onerror = (error) => {
      console.error(`Error occurred while loading image ${url}`, error);
      reject(error);
    };
    img.src = url;
  });
};

class Part {
  constructor(partId, names) {
    this.partId = partId;
    this.names = names;
  }
}

class UserFriendlyColor {
  constructor(colorName, colorCode, colorHtml, gameCode) {
    this.colorName = colorName;
    this.colorCode = colorCode;
    this.colorHtml = colorHtml;
    this.gameCode = gameCode;
  }
}

const CharacterCustomizationPage = () => {
  const loadPalette = async (color) => {
    // Construct the filename
    console.log(`Loading ${color}`);

    // Load the image
    const img = await createImage(color).catch((error) => {
      console.error(`Error occurred during image creation for ${color}`, error);
    });

    // Create a canvas and draw the image on it
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    // Get the image data
    const imageData = ctx.getImageData(0, 0, img.width, img.height);

    // Initialize the palette array
    const palette = [];

    // Get the first 7 pixels
    for (let i = 0; i < 7; i++) {
      const index = i * 4; // each pixel is represented by 4 values (r, g, b, a)
      const r = imageData.data[index];
      const g = imageData.data[index + 1];
      const b = imageData.data[index + 2];

      // Add the color to the palette
      palette.push(`${r}-${g}-${b}`);
    }

    return palette;
  };

  const [bodyIndex] = useState(0);
  const [hairIndex, setHairIndex] = useState(1);
  const [topIndex, setTopIndex] = useState(1);
  const [bottomIndex, setBottomIndex] = useState(1);
  const [accessoryIndex, setAccessoryIndex] = useState(0);

  const [bodyImages, setBodyImages] = useState(null);
  const bodyColors = [new UserFriendlyColor("Skin 1", "skin_0", "#C1AC8F", "090"), new UserFriendlyColor("Skin 2", "skin_1", "#925B39", "091"), new UserFriendlyColor("Skin 3", "skin_2", "#613317", "092"), new UserFriendlyColor("Skin 4", "skin_3", "#40220F", "093")];
  const [hairImages, setHairImages] = useState(null);
  const hairNames = ["Bald", "Mid", "Short 1", "Long", "Beard", "Beard 2", "Short 2"];
  const [topImages, setTopImages] = useState(null);
  const topNames = ["None", "Jacket", "Shirt", "Tank Top", "Timber", "Traveler", "Armor"];
  const [bottomImages, setBottomImages] = useState(null);
  const bottomNames = ["None", "Pants", "Camo", "Skirt", "Armor"];
  const [accessoryImages, setAccessoryImages] = useState(null);
  const accessoryNames = ["None", "Beanie", "Merchant", "Beret", "Glasses", "Top Hat", "Sombrero", "Helmet"];

  const [skinColorIndex, setSkinColorIndex] = useState(0);
  const [hairColorIndex, setHairColorIndex] = useState(4);
  const [topColorIndex, setTopColorIndex] = useState(4);
  const [topColor2Index, setTopColor2Index] = useState(0);
  const [bottomColorIndex, setBottomColorIndex] = useState(15);
  const [bottomColor2Index, setBottomColor2Index] = useState(5);
  const [accessoryColorIndex, setAccessoryColorIndex] = useState(9);
  const [accessoryColor2Index, setAccessoryColor2Index] = useState(6);

  const [bodyColor, setBodyColor] = useState("skin_0");
  const [hairColor, setHairColor] = useState("brown");
  const [topColor, setTopColor] = useState("brown");
  const [topColor2, setTopColor2] = useState("white");
  const [bottomColor, setBottomColor] = useState("dblue");
  const [bottomColor2, setBottomColor2] = useState("dbrown");
  const [accessoryColor, setAccessoryColor] = useState("red");
  const [accessoryColor2, setAccessoryColor2] = useState("yellow");

  const [colors, setColors] = useState([
    new UserFriendlyColor("White", "white", "#ffffff", "000"), 
    new UserFriendlyColor("Grey", "grey", "#666666", "023"), 
    new UserFriendlyColor("Black", "black", "#000000", "016"), 
    new UserFriendlyColor("Light Brown", "lbrown", "#A06B40", "019"), 
    new UserFriendlyColor("Brown", "brown", "#563A23", "020"), 
    new UserFriendlyColor("Dark Brown", "dbrown", "#4C2709", "021"), 
    new UserFriendlyColor("Yellow", "yellow", "#FDDB00", "001"), 
    new UserFriendlyColor("Dark Yellow", "dyellow", "#D59B00", "022"), 
    new UserFriendlyColor("Orange", "orange", "#FD7C00", "002"), 
    new UserFriendlyColor("Red", "red", "#D23427", "003"), 
    new UserFriendlyColor("Pink", "pink", "#FE00DF", "013"), 
    new UserFriendlyColor("Purple", "purple", "#AD00BF", "011"), 
    new UserFriendlyColor("Violet", "violet", "#7F00FF", "010"), 
    new UserFriendlyColor("Light Blue", "lblue", "#00AAFF", "009"),
    new UserFriendlyColor("Blue", "blue", "#1500F5", "007"), 
    new UserFriendlyColor("Dark Blue", "dblue", "#0B0081", "008"), 
    new UserFriendlyColor("Teal", "teal", "#007F7F", "014"), 
    new UserFriendlyColor("Light Green", "lgreen", "#00FE00", "017"), 
    new UserFriendlyColor("Green", "green", "#00B200", "005"),
    new UserFriendlyColor("Metallic Grey", "lgrey", "#c5c5c5", "024"),]);

  const [animation] = useState('idle');
  const [index, setIndex] = useState(0);

  const idleAnimation = {
    idle: [0, 0, 32, 32,    // frame 1
      32, 0, 32, 32,   // frame 2
      64, 0, 32, 32,   // frame 3
      96, 0, 32, 32,   // frame 4
      128, 0, 32, 32,  // frame 5
      160, 0, 32, 32   // frame 6
    ]
  };

  const scale = 10;

  function colorImage(imageElement, defaultPalette, colorPalette) {
    const canvas = document.createElement('canvas');
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;

    const context = canvas.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.drawImage(imageElement, 0, 0);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;

    let buffer = new Array(data.length); // Creating a new buffer

    for (let i = 0; i < data.length; i += 4) {
      let currentColor = `${data[i]}-${data[i + 1]}-${data[i + 2]}`;
      let colorIndex = defaultPalette.indexOf(currentColor);

      if (colorIndex !== -1) {
        let newColor = colorPalette[colorIndex];
        let colors = newColor.split("-");
        buffer[i] = colors[0];
        buffer[i + 1] = colors[1];
        buffer[i + 2] = colors[2];
        buffer[i + 3] = data[i + 3]; // Keep the alpha channel
      } else {
        // If there's no color change, copy from the existing data
        buffer[i] = data[i];
        buffer[i + 1] = data[i + 1];
        buffer[i + 2] = data[i + 2];
        buffer[i + 3] = data[i + 3];
      }
    }

    // apply buffer onto imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = buffer[i];
    }

    context.putImageData(imageData, 0, 0);
    const coloredImage = new Image();
    coloredImage.src = canvas.toDataURL();
    return coloredImage;
  }

  const [colorsDict, setColorsDict] = useState(undefined);

  useEffect(() => {
    const loadDict = async () => {
      const imagesContextPalettes = require.context('../../Resources/Palettes', false, /\.png$/);
      const imagesDict = {};
      for (const path of imagesContextPalettes.keys()) {
        const filename = path.substring(path.lastIndexOf('/') + 1).replace(".png", "");
        const colorArray = await loadPalette(imagesContextPalettes(path));
        imagesDict[filename] = {
          url: imagesContextPalettes(path), palette: colorArray
        };
      }
      setColorsDict(imagesDict);
    }
    loadDict().then(r => console.log("Setting colors dict..."));
  }, []);

  function applyColors() {
    if (colorsDict == null) return;

    const importAll = async (r, color, color2) => {
      try {
        const imageUrls = r.keys().map(r);
        let imagesPromises = imageUrls.map((imageUrl) => createImage(imageUrl));

        let loadedImages = await Promise.all(imagesPromises);

        if (color) {
          loadedImages = loadedImages.filter(image => image !== null);
          imagesPromises = loadedImages.map(image => colorImage(image, colorsDict["default"].palette, colorsDict[color].palette));
          loadedImages = await Promise.all(imagesPromises);
          loadedImages = loadedImages.filter(image => image !== null);
        }

        if (color2) {
          loadedImages = loadedImages.filter(image => image !== null);
          imagesPromises = loadedImages.map(image => colorImage(image, colorsDict["default2"].palette, colorsDict[color2].palette));
          loadedImages = await Promise.all(imagesPromises);
          loadedImages = loadedImages.filter(image => image !== null);
        }

        return loadedImages;
      } catch (error) {
        console.error("Error in importAll function: ", error);
        throw error;
      }
    };

    // Current image context
    // ToDo: Don't load everything again
    const imagesContextBody = require.context('../../Resources/Body', false, /\.png$/);
    const imagesContextHair = require.context('../../Resources/Hair', false, /\.png$/);
    const imagesContextTop = require.context('../../Resources/Top', false, /\.png$/);
    const imagesContextBottom = require.context('../../Resources/Bottom', false, /\.png$/);
    const imagesContextAccessories = require.context('../../Resources/Accessories', false, /\.png$/);

    importAll(imagesContextBody, bodyColor, null).then(setBodyImages).catch(console.error);
    importAll(imagesContextHair, hairColor, null).then(setHairImages).catch(console.error);
    importAll(imagesContextTop, topColor, topColor2).then(setTopImages).catch(console.error);
    importAll(imagesContextBottom, bottomColor, bottomColor2).then(setBottomImages).catch(console.error);
    importAll(imagesContextAccessories, accessoryColor, accessoryColor2).then(setAccessoryImages).catch(console.error);
  }

  useEffect(() => {
    console.log("Images Stored:", colorsDict);
    applyColors();
  }, [colorsDict]);

  useEffect(() => {
    applyColors();
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % 6);
    }, 100);

    return () => clearInterval(interval);
  }, [bodyColor, hairColor, topColor, bottomColor, accessoryColor, topColor2, bottomColor2, accessoryColor2]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeMenuIndex, setActiveMenuIndex] = useState(0);
  const [menuItems, setMenuItems] = useState({});
  const [colorMenuItems, setColorMenuItems] = useState({});

  const [colorMenuOpen, setColorMenuOpen] = useState(false);
  const [colorMenuOptions, setColorMenuOptions] = useState([]);

  function OpenMenu(partId, partIndex) {
    setColorMenuOpen(false)
    setMenuOpen(!menuOpen);

    if (menuOpen) {
      return;
    }

    setActiveMenuIndex(partIndex);
    if (partId === 1) {
      setMenuItems(new Part(partId, hairNames));
    } else if (partId === 2) {
      setMenuItems(new Part(partId, topNames));
    } else if (partId === 3) {
      setMenuItems(new Part(partId, bottomNames));
    } else if (partId === 4) {
      setMenuItems(new Part(partId, accessoryNames));
    }
  }

  function OpenColorMenu(partId, partIndex) {
    setMenuOpen(false);
    setColorMenuOpen(!colorMenuOpen)
    setActiveMenuIndex(partIndex);

    if (partId == null) return;

    if (partId === 0) {
      setColorMenuItems(new Part(partId, bodyColors));
    } else setColorMenuItems(new Part(partId, colors));
  }

  function SetSkinIndex(partId, partIndex) {
    setActiveMenuIndex(partIndex)
    if (partId === 1) {
      setHairIndex(partIndex);
    } else if (partId === 2) {
      setTopIndex(partIndex);
    } else if (partId === 3) {
      setBottomIndex(partIndex);
    } else if (partId === 4) {
      setAccessoryIndex(partIndex);
    }
  }

  function SetSkinColorIndex(partId, partIndex) {
    setActiveMenuIndex(partIndex)
    if (partId === 0) {
      setSkinColorIndex(partIndex);
      setBodyColor(bodyColors[partIndex].colorCode);
    } else if (partId === 1) {
      setHairColorIndex(partIndex);
      setHairColor(colors[partIndex].colorCode);
    } else if (partId === 2) {
      setTopColorIndex(partIndex);
      setTopColor(colors[partIndex].colorCode);
    } else if (partId === 3) {
      setBottomColorIndex(partIndex);
      setBottomColor(colors[partIndex].colorCode);
    } else if (partId === 4) {
      setAccessoryColorIndex(partIndex);
      setAccessoryColor(colors[partIndex].colorCode);
    } else if (partId === 5) {
      setTopColor2Index(partIndex);
      setTopColor2(colors[partIndex].colorCode);
    } else if (partId === 6) {
      setBottomColor2Index(partIndex);
      setBottomColor2(colors[partIndex].colorCode);
    } else if (partId === 7) {
      setAccessoryColor2Index(partIndex);
      setAccessoryColor2(colors[partIndex].colorCode);
    }
  }

  function getTextColorByHtml(colorHtml) {
    switch (colorHtml) {
      case '#ffffff':
        return 'black';
      case "#666666":
        return 'white';
      case "#000000":
        return 'white';
      case "#A06B40":
        return 'white';
      case "#563A23":
        return 'white';
      case "#4C2709":
        return 'white';
      case "#FDDB00":
        return 'black';
      case "#D59B00":
        return 'black';
      case "#FD7C00":
        return 'black';
      case "#D23427":
        return 'white';
      case "#FE00DF":
        return 'black';
      case "#AD00BF":
        return 'white';
      case "#7F00FF":
        return 'white';
      case "#00AAFF":
        return 'black';
      case "#1500F5":
        return 'white';
      case "#0B0081":
        return 'white';
      case "#007F7F":
        return 'white';
      case "#00FE00":
        return 'black';
      case "#00B200":
        return 'black';
      default:
        return 'white';
    }
  }

  const [isCopied, setCopied] = useState(false);

  const userSentences = [
    "I want this as my skin!",
    "This is literally me.",
    "I choose this!",
    "Perfect match for my style!",
    "This is the skin combo I've been looking for.",
    "Absolutely love this combination!",
    "Could this get any better? I think not!",
    "Wow, this is exactly what I wanted!",
    "This combo really speaks to me!",
    "Unbelievable. This is a must-have!",
    "How did I ever live without this skin combo?",
  ];

  const randomNumber = Math.floor(Math.random() * userSentences.length);

  const copyToClipboard = async e => {
    e.preventDefault();
    console.log("Copying")
    await navigator.clipboard.writeText(userSentences[randomNumber] + " " + "skin-1" + bodyColors[skinColorIndex].gameCode + "-" + hairIndex + (hairIndex !== 0 ? colors[hairColorIndex].gameCode : '') + "-" + topIndex + (topIndex !== 0 ? colors[topColorIndex].gameCode + colors[topColor2Index].gameCode : '') + "-" + bottomIndex + (bottomIndex !== 0 ? colors[bottomColorIndex].gameCode + colors[bottomColor2Index].gameCode : '') + "-" + accessoryIndex + (accessoryIndex !== 0 ? colors[accessoryColorIndex].gameCode + colors[accessoryColor2Index].gameCode : ''));
    setCopied(true);
    console.log("Copied")

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  
  return (<main className={"characterEditorContainer"}>
    <h1 style={{margin: "8px 0"}}>Character Editor</h1>
    <HomeButton />
    <div style={{marginBottom: "21px"}} className={"setSkinButtonsRow"}>
        <span onClick={copyToClipboard} className={"copySkinCodeButton"}>
          Copy skin code
        </span>
    </div>

    {isCopied && <span style={{color: "green", marginTop: "-21px"}}>Skin copied to clipboard!</span>}
    {isCopied && <span style={{color: "green", marginTop: "-21px"}}>Go and paste it into the Pinned Reel About skins to set it!</span>}
    <span>{"skin-1" + bodyColors[skinColorIndex].gameCode + "-" + hairIndex + (hairIndex !== 0 ? colors[hairColorIndex].gameCode : '') + "-" + topIndex + (topIndex !== 0 ? colors[topColorIndex].gameCode + colors[topColor2Index].gameCode : '') + "-" + bottomIndex + (bottomIndex !== 0 ? colors[bottomColorIndex].gameCode + colors[bottomColor2Index].gameCode : '') + "-" + accessoryIndex + (accessoryIndex !== 0 ? colors[accessoryColorIndex].gameCode + colors[accessoryColor2Index].gameCode : '')}</span>

    {/*<p>Default skin by <a style={{color: "white"}} href="https://game-endeavor.itch.io/mystic-woods">Game-Endeavor on Itch.io</a></p>*/}
    {colorsDict === undefined && <div style={{height: 32 * scale - 48}}></div>}
    {colorsDict !== undefined && <Stage style={{marginTop: "-32px", marginBottom: "-16px"}} width={32 * scale} height={32 * scale}>
      <Layer imageSmoothingEnabled={false}>
        {bodyImages != null && bodyImages[bodyIndex] && <Sprite
          image={bodyImages[bodyIndex]}
          animation={animation}
          animations={idleAnimation}
          frameRate={10}
          frameIndex={index}
          scaleX={scale}
          scaleY={scale}
        />}
        {bottomImages != null && bottomImages[bottomIndex] && <Sprite
          image={bottomImages[bottomIndex]}
          animation={animation}
          animations={idleAnimation}
          frameRate={10}
          frameIndex={index}
          scaleX={scale}
          scaleY={scale}
        />}
        {topImages != null && topImages[topIndex] && <Sprite
          image={topImages[topIndex]}
          animation={animation}
          animations={idleAnimation}
          frameRate={10}
          frameIndex={index}
          scaleX={scale}
          scaleY={scale}
        />}
        {hairImages != null && hairImages[hairIndex] && <Sprite
          image={hairImages[hairIndex]}
          animation={animation}
          animations={idleAnimation}
          frameRate={10}
          frameIndex={index}
          scaleX={scale}
          scaleY={scale}
        />}
        {accessoryImages != null && accessoryImages[accessoryIndex] && <Sprite
          image={accessoryImages[accessoryIndex]}
          animation={animation}
          animations={idleAnimation}
          frameRate={10}
          frameIndex={index}
          scaleX={scale}
          scaleY={scale}
        />}
      </Layer>
    </Stage>}

    {skinColorIndex !== null && <div className={"customizationRow"}>
      <span className={"label"}>Skin</span>
      <span style={{backgroundColor: bodyColors[skinColorIndex].colorHtml, color: getTextColorByHtml(bodyColors[skinColorIndex].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(0, skinColorIndex)}>Color</span>
    </div>}

    {hairColorIndex !== null && <div className={"customizationRow"}>
      <span className={"label"}>Hair</span>
      <span className={"skinActionButton"} onClick={() => OpenMenu(1, hairIndex)}>{hairNames[hairIndex]}</span>
      <span style={{backgroundColor: colors[hairColorIndex].colorHtml, color: getTextColorByHtml(colors[hairColorIndex].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(1, hairColorIndex)}>Color</span>
    </div>}

    {topColorIndex !== null && <div className={"customizationRow"}>
      <span className={"label"}>Top</span>
      <span className={"skinActionButton"} onClick={() => OpenMenu(2, topIndex)}>{topNames[topIndex]}</span>
      <span style={{backgroundColor: colors[topColorIndex].colorHtml, color: getTextColorByHtml(colors[topColorIndex].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(2, topColorIndex)}>Color</span>
      <span style={{backgroundColor: colors[topColor2Index].colorHtml, color: getTextColorByHtml(colors[topColor2Index].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(5, topColor2Index)}>Color 2</span>
    </div>}

    {bottomColorIndex !== null && <div className={"customizationRow"}>
      <span className={"label"}>Bottom</span>
      <span className={"skinActionButton"} onClick={() => OpenMenu(3, bottomIndex)}>{bottomNames[bottomIndex]}</span>
      <span style={{backgroundColor: colors[bottomColorIndex].colorHtml, color: getTextColorByHtml(colors[bottomColorIndex].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(3, bottomColorIndex)}>Color</span>
      <span style={{backgroundColor: colors[bottomColor2Index].colorHtml, color: getTextColorByHtml(colors[bottomColor2Index].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(6, bottomColor2Index)}>Color 2</span>
    </div>}

    {accessoryColorIndex !== null && <div className={"customizationRow"}>
      <span className={"label"}>Acces.</span>
      <span className={"skinActionButton"} onClick={() => OpenMenu(4, accessoryIndex)}>{accessoryNames[accessoryIndex]}</span>
      <span style={{backgroundColor: colors[accessoryColorIndex].colorHtml, color: getTextColorByHtml(colors[accessoryColorIndex].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(4, accessoryColorIndex)}>Color</span>
      <span style={{backgroundColor: colors[accessoryColor2Index].colorHtml, color: getTextColorByHtml(colors[accessoryColor2Index].colorHtml)}}
            className={"actionButton"}
            onClick={() => OpenColorMenu(7, accessoryColor2Index)}>Color 2</span>
    </div>}


    <div className={`menu ${menuOpen ? 'menuOpen' : 'menuClosed'}`}>
      {menuItems.names?.map((item, index) => (
        <span key={index} className={`menuItem ${index === activeMenuIndex ? 'active' : null}`} onClick={() => SetSkinIndex(menuItems.partId, index)}>
            {menuItems.names[index]}
          </span>))}
      <div className="menuActions">
        <span onClick={() => OpenMenu(null, null)}>Confirm</span>
        <span onClick={() => OpenColorMenu(menuItems.partId, activeMenuIndex)}>Select color</span>
      </div>
    </div>

    <div className={`colorsMenu ${colorMenuOpen ? 'menuOpen' : 'menuClosed'}`}>
      {colorMenuItems.names?.map((item, index) => (
        <span key={index} style={{backgroundColor: item.colorHtml}} className={`menuColorItem ${index === activeMenuIndex ? 'active' : null}`}
              onClick={() => SetSkinColorIndex(colorMenuItems.partId, index)}>
          </span>))}
      <div className="menuActions">
        <span onClick={() => OpenColorMenu(null, null)}>Confirm</span>
      </div>
    </div>
  </main>);
};

export default CharacterCustomizationPage;