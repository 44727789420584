import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import './UserSelect.css';
import HomeButton from "../../Components/HomeButton/HomeButton";
import {useNavigate} from 'react-router-dom';
import Accordion from "../../Components/Accordion/Accordion";
import WarningDiv from "../../Components/WarningDiv/WarningDiv";

const UserSelectPage = () => {
  const [inputValue, setInputValue] = useState('');

  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = () => {
    if (inputValue) {
      sessionStorage.clear();
      navigate(`/user/${inputValue.toLowerCase()}`);
    }
  };

  return (
    <main className={"scoreboardContainer"}>
      <h1 style={{margin: "8px 0"}}>User Stats Viewer</h1>
      <HomeButton/>
      <input
        style={{margin: "8px 0"}}
        type='text'
        value={inputValue}
        placeholder='Enter username...'
        onChange={handleInputChange}
      />
      <input style={{margin: "8px 0"}} type='button' value='Find User' onClick={handleSubmit}/>
    </main>
  );
};

export default UserSelectPage;