import React from 'react';
import {useNavigate} from 'react-router-dom';
import './HomeButton.css';

const HomeButton = ({url = '/', goBack = false}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    let savedUrls = sessionStorage.getItem('savedUrls');
    savedUrls = savedUrls ? JSON.parse(savedUrls) : [];

    if (goBack && savedUrls.length > 0) {
      const lastUrl = savedUrls.pop();
      sessionStorage.setItem('savedUrls', JSON.stringify(savedUrls));
      navigate(lastUrl);
    } else {
      navigate(url);
    }
  };

  return (
    <span className={"homeButton"} onClick={handleNavigation}>
      <div className={"lt-symbol"}></div>
    </span>
  );
};

export default HomeButton;