import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import './Scoreboard.css';
import HomeButton from "../../Components/HomeButton/HomeButton";
import {useNavigate} from 'react-router-dom';

const ScoreboardPage = () => {
  const [category, setCategory] = useState(null);
  const [battleId, setBattleId] = useState(null);
  const [battleData, setBattleData] = useState(null);
  const [filterFunc, setFilterFunc] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setFilterFunc("");
    let pathElems = location.pathname.split("/scoreboard/");
    if (pathElems.length === 1) {
      const category = "season-1";
      const battleId = "54";
      setCategory(category);
      setBattleId(battleId);
      console.log("Default");
      fetchData(category, battleId);
    } else {
      let battleData = pathElems[1].split("/");
      if (battleData.length !== 2) {
        console.error("Invalid battle url.")
        navigate('/scoreboard');
        return;
      }

      const category = battleData[0];
      const battleId = battleData[1];
      setCategory(category);
      setBattleId(battleId);
      console.log("Manual");
      fetchData(category, battleId);
    }
  }, [location.pathname]);

  const fetchData = (category, battleId) => {
    const filePath = `/Logs/${category}/logs_${battleId}.json`;
    console.log(filePath);
    fetch(filePath)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json();
      })
      .then(data => {
          setBattleData(data);
          for (let i = 0; i < data.logs.length; i++) {
            data.logs[i].position = i + 1;
          }
        }
      )
      .catch(err => {
        console.error("Battle not found.")
        navigate('/scoreboard');
      });
  }

  const handleFilterInputChange = event => {
    setFilterFunc(event.target.value.toLowerCase());
  }

  const navigateWithHistory = url => {
    let savedUrls = sessionStorage.getItem('savedUrls');
    savedUrls = savedUrls ? JSON.parse(savedUrls) : [];
    savedUrls.push(window.location.pathname);
    sessionStorage.setItem('savedUrls', JSON.stringify(savedUrls));
    navigate(`/user/${url}`);
  }

  return (
    <main>
      {battleData && <h1 style={{marginLeft: "64px", marginTop: "8px"}}>Battle Royale {battleId} - {battleData.logs.length} players</h1>}
      <HomeButton goBack={true}/>
      <input type="text" placeholder="Filter by player name" onChange={handleFilterInputChange} content=""/>
      {battleData && (
        <div className={'scrollBarDiv'}>
          <table>
            <thead>
            <tr>
              <th>Position</th>
              <th className="time-header">Time</th>
              <th className="player-header">Player</th>
              <th className="player-header"></th>
              <th className="player-kills-header">Player Kills</th>
              <th className="killed-by-header">Killed By</th>
              <th className="player-title-header">Player Title</th>
            </tr>
            </thead>
            <tbody>
            {battleData.logs.filter(item =>
              filterFunc === "" ||
              item.player.toLowerCase().includes(filterFunc) ||
              item.killedBy.toLowerCase().includes(filterFunc)
            ).map((item, index) => (
              <tr key={index} className={item.killedBy == "" ? "winnerRow" : null}>
                <td>{item.position}</td>
                <td>{item.time ? parseFloat(item.time).toFixed(2) : ''}</td>
                <td>{item.player}</td>
                <td><span className={"profileViewBtn"} onClick={() => navigateWithHistory(item.player)}>View Profile</span></td>
                <td>{item.playerKills}</td>
                <td>{item.killedBy}</td>
                <td>{item.playerTitle}</td>
              </tr>
            ))
            }
            </tbody>
          </table>
        </div>
      )}
    </main>
  );
};

export default ScoreboardPage;