import React, {useState} from 'react';
import './BattleSimulatorPage.css';
import HomeButton from "../../Components/HomeButton/HomeButton";

const BattleSimulatorPage = () => {
  const playerStats =
    {
      hp: 20,
      attack: 5,
      defense: 5,
      specialAttack: 5,
      specialDefense: 5
    };

  const statsInitial = [
    {
      name: "Slime",
      rank: 1,
      rarity: 100,
      hp: 10,
      attack: 4,
      defense: 4,
      specialAttack: 4,
      specialDefense: 4
    }
  ];

  const [statsCoefficient] = useState(.5);
  const [enemyLevel, setEnemyLevel] = useState(1);
  const [playerLevel, setPlayerLevel] = useState(1);
  const [enemyStats, setEnemyStats] = useState(statsInitial);
  const [calculatedEnemyStats, setCalculatedEnemyStats] = useState(statsInitial[0]);
  const [calculatedPlayerStats, setCalculatedPlayerStats] = useState(playerStats);
  const [battleLog, setBattleLog] = useState([]);

  const handleLevelChange = (e) => {
    const newLevel = parseInt(e.target.value);
    setEnemyLevel(newLevel);

    // Apply level multiplier to the stats
    let i = 0;
    const newStats = {
      name: enemyStats[i].name,
      hp: enemyStats[i].hp + ((newLevel - 1) * enemyStats[i].hp * statsCoefficient),
      attack: enemyStats[i].attack + ((newLevel - 1) * enemyStats[i].attack * statsCoefficient),
      defense: enemyStats[i].defense + ((newLevel - 1) * enemyStats[i].defense * statsCoefficient),
      specialAttack: enemyStats[i].specialAttack + ((newLevel - 1) * enemyStats[i].specialAttack * statsCoefficient),
      specialDefense: enemyStats[i].specialDefense + ((newLevel - 1) * enemyStats[i].specialDefense * statsCoefficient),
    };

    setCalculatedEnemyStats(newStats);
  };

  const handlePlayerLevelChange = (e) => {
    const newLevel = parseInt(e.target.value);
    setPlayerLevel(newLevel);

    // Apply level multiplier to the stats
    const newStats = {
      hp: playerStats.hp + ((newLevel - 1) * playerStats.hp * statsCoefficient),
      attack: playerStats.attack + ((newLevel - 1) * playerStats.attack * statsCoefficient),
      defense: playerStats.defense + ((newLevel - 1) * playerStats.defense * statsCoefficient),
      specialAttack: playerStats.specialAttack + ((newLevel - 1) * playerStats.specialAttack * statsCoefficient),
      specialDefense: playerStats.specialDefense + ((newLevel - 1) * playerStats.specialDefense * statsCoefficient),
    };

    setCalculatedPlayerStats(newStats);
  };

  const clearLog = (e) => {
    setBattleLog([]);
  };

  const simulatePlayerAttack = (e) => {
    let attackModifier = 1; // Depends on the attack
    let damages = attackModifier * calculatedPlayerStats.attack - calculatedEnemyStats.defense / 2;
    if (damages < 0) {
      damages = 0;
    }

    let newBattleLog = [...battleLog, `Player inflicted ${damages} damages to enemy.`];
    setBattleLog(newBattleLog);
  };

  const simulateEnemyAttack = (e) => {
    let attackModifier = 1; // Depends on the attack
    let damages = attackModifier * calculatedEnemyStats.attack - calculatedPlayerStats.defense / 2;
    if (damages < 0) {
      damages = 0;
    }

    let newBattleLog = [...battleLog, `Enemy inflicted ${damages} damages to player.`];
    setBattleLog(newBattleLog);
  };

  return (
    <main className={"simulatorContainer"}>
      <h1 style={{margin: "8px 0"}}>Battle Simulator</h1>
      <HomeButton/>
      <div className={"battleContainer"}>
        <div>
          <input type="number" min="1" max="1000" step="1" value={playerLevel} onChange={handlePlayerLevelChange}/>
          <div>
            <h2>Player</h2>
            <p>HP: {calculatedPlayerStats.hp}</p>
            <p>Attack: {calculatedPlayerStats.attack}</p>
            <p>Defence: {calculatedPlayerStats.defense}</p>
            <p>Special Attack: {calculatedPlayerStats.specialAttack}</p>
            <p>Special Defence: {calculatedPlayerStats.specialDefense}</p>
          </div>
        </div>
        <div>
          <input type="number" min="1" max="1000" step="1" value={enemyLevel} onChange={handleLevelChange}/>
          <div>
            <h2>{calculatedEnemyStats.name}</h2>
            <p>HP: {calculatedEnemyStats.hp}</p>
            <p>Attack: {calculatedEnemyStats.attack}</p>
            <p>Defence: {calculatedEnemyStats.defense}</p>
            <p>Special Attack: {calculatedEnemyStats.specialAttack}</p>
            <p>Special Defence: {calculatedEnemyStats.specialDefense}</p>
          </div>
        </div>
      </div>
      <div className={"battleContainer"}>
        <div>
          <button onClick={clearLog}>Clear Log</button>
        </div>
      </div>
      <div className={"battleContainer"}>
        <div>
          <button onClick={simulatePlayerAttack}>Player Attack</button>
        </div>
        <div>
          <button onClick={simulateEnemyAttack}>Enemy Attack</button>
        </div>
      </div>
      <div>
        {battleLog.map((log) => <p>{log}</p>)}
      </div>
    </main>
  );
};

export default BattleSimulatorPage;