import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import './App.css';
import ScoreboardPage from "./Pages/ScoreboardPage/Scoreboard";
import AboutPage from "./Pages/AboutPage/AboutPage";
import HistoryPage from "./Pages/HistoryPage/HistoryPage";
import CharacterCustomizationPage from "./Pages/CharacterCustomizationPage/CharacterCustomizationPage";
import HomePage from "./Pages/HomePage/Home";
import UserPage from "./Pages/UserSelectPage/UserPage/User";
import UserSelectPage from "./Pages/UserSelectPage/UserSelect";
import BattleSimulatorPage from "./Pages/BattleSimulatorPage/BattleSimulatorPage";

function App() {
  return (
    <div className="App">
      <Router>
        {/*<header className="App-header">*/}
        {/*  <Nav/>*/}
        {/*</header>*/}
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/scoreboard/*" element={<ScoreboardPage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/character-customization" element={<CharacterCustomizationPage/>}/>
          <Route path="/user" element={<UserSelectPage/>}/>
          <Route path="/user/:battleId" element={<UserPage/>}/>
          <Route path="/last-battle/:userId" element={<ScoreboardPage/>}/>
          <Route path="/battles-history" element={<HistoryPage/>}/>
          <Route path="/battles-simulator" element={<BattleSimulatorPage/>}/>
          <Route path="/*" element={<Navigate to="/"/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;