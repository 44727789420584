import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Accordion.css';

const Accordion = ({ title, items }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const navigateToPage = (route) => {
    console.log(route)
    navigate(route);
  }

  return (
    <div className={"accordion"}>
      <h2 onClick={() => setIsOpen(!isOpen)}><span>{title}</span>
        <div className={isOpen ? "accordion-arrow" : "accordion-arrow rotate"}></div>
      </h2>
      {isOpen && <div>
        {items.map((item, index) => (
          <h4 key={index} onClick={() => navigateToPage(item.Link)}>
            <span>{item.DisplayName}</span>
            <span>See results</span>
          </h4>
        ))}
      </div>}
    </div>
  );
};

export default Accordion;