import React from 'react';

const AboutPage = () => {
  return (
    <main>
      <h1>About Us</h1>
      <p>This is some about us text...</p>
    </main>
  );
};

export default AboutPage;