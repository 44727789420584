import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import './User.css';
import {useNavigate} from 'react-router-dom';
import HomeButton from "../../../Components/HomeButton/HomeButton";
import {Layer, Sprite, Stage} from "react-konva";

class UserFriendlyColor {
  constructor(colorCode, gameCode) {
    this.colorCode = colorCode;
    this.gameCode = gameCode;
  }
}

const UserPage = () => {
  const [userId, setUserId] = useState(null);
  const [userData, setUserData] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let pathElems = location.pathname.split("/user/");
    if (pathElems.length === 1) {
      console.error("Invalid user name.")
      navigate('/user');
    } else {
      let battleData = pathElems[1].split("/");
      if (battleData.length !== 1) {
        console.error("Invalid user name.")
        navigate('/user');
        return;
      }

      const userId = battleData[0];
      setUserId(userId);
      fetchData(userId);
    }
  }, [location.pathname]);

  const fetchData = (userId) => {
    const filePath = `/PlayerStats/season-1/char${userId[0]}.json`;
    console.log(filePath);
    fetch(filePath)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json();
      })
      .then(data => {
          let user = data.find(user => user.PlayerName.toLowerCase() === userId.toLowerCase())
          if (user) {
            user.Season = 1;
            setUserData(user);
          } else {
            console.error("User not found.");
            navigate('/user');
          }
        }
      )
      .catch(err => {
        console.error("User not found.")
        navigate('/user');
      });
  }

  const [colorsDict, setColorsDict] = useState(undefined);

  const loadDict = async () => {
    const imagesContextPalettes = require.context('../../../Resources/Palettes', false, /\.png$/);
    const imagesDict = {};
    for (const path of imagesContextPalettes.keys()) {
      const filename = path.substring(path.lastIndexOf('/') + 1).replace(".png", "");
      const colorArray = await loadPalette(imagesContextPalettes(path));
      imagesDict[filename] = {
        url: imagesContextPalettes(path), palette: colorArray
      };
    }
    setColorsDict(imagesDict);
  }

  const loadPalette = async (color) => {
    // Construct the filename
    console.log(`Loading ${color}`);

    // Load the image
    const img = await createImage(color).catch((error) => {
      console.error(`Error occurred during image creation for ${color}`, error);
    });

    // Create a canvas and draw the image on it
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = false;
    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0, img.width, img.height);

    // Get the image data
    const imageData = ctx.getImageData(0, 0, img.width, img.height);

    // Initialize the palette array
    const palette = [];

    // Get the first 7 pixels
    for (let i = 0; i < 7; i++) {
      const index = i * 4; // each pixel is represented by 4 values (r, g, b, a)
      const r = imageData.data[index];
      const g = imageData.data[index + 1];
      const b = imageData.data[index + 2];

      // Add the color to the palette
      palette.push(`${r}-${g}-${b}`);
    }

    return palette;
  };

  useEffect(() => {
    loadDict().then(r => console.log("Setting colors dict..."));
  }, [userData]);

  useEffect(() => {
    console.log("Images Stored:", colorsDict);
    applyColors();
  }, [colorsDict]);

  const [bodyImage, setBodyImage] = useState(null);
  const [hairImage, setHairImage] = useState(null);
  const [topImage, setTopImage] = useState(null);
  const [bottomImage, setBottomImage] = useState(null);
  const [accessoryImage, setAccessoryImage] = useState(null);

  function applyColors() {
    if (colorsDict == null) return;
    if (userData == null) return;

    const importAll = async (r, color, color2) => {
      try {
        let image = await createImage(r);

        if (color) {
          image = await colorImage(image, colorsDict["default"].palette, colorsDict[color].palette);
        }

        if (color2) {
          image = await colorImage(image, colorsDict["default2"].palette, colorsDict[color2].palette);
        }

        return image;
      } catch (error) {
        console.error("Error in importAll function: ", error);
        throw error;
      }
    };

    let skin = parseSkinData(userData.PlayerSkin)
    console.log(skin)

    if (skin.bodyId != 961) {
      if (skin.bodyId == 1) {
        skin.bodyId = 0;
      }

      if (skin.bodyColor == "") {
        skin.bodyColor = "090";
      }

      if (skin.hairColor == "") {
        skin.hairColor = "020"
      }

      if (skin.hairColor == "") {
        skin.hairColor = "020"
      }

      if (skin.topPrimaryColor == "") {
        skin.topPrimaryColor = "020"
      }

      if (skin.topSecondaryColor == "") {
        skin.topSecondaryColor = "000"
      }

      if (skin.bottomPrimaryColor == "") {
        skin.bottomPrimaryColor = "008"
      }

      if (skin.bottomSecondaryColor == "") {
        skin.bottomSecondaryColor = "021"
      }

      if (skin.accessoryPrimaryColor == "") {
        skin.accessoryPrimaryColor = "003"
      }

      if (skin.accessorySecondaryColor == "") {
        skin.accessorySecondaryColor = "001"
      }
    }

    const imagesContextBody = require(`../../../Resources/Body/Body_${skin.bodyId}.png`);
    const imagesContextHair = require(`../../../Resources/Hair/Hair_${skin.hairId}.png`);
    const imagesContextTop = require(`../../../Resources/Top/Top_${skin.topId}.png`);
    const imagesContextBottom = require(`../../../Resources/Bottom/Bottom_${skin.bottomId}.png`);
    const imagesContextAccessories = require(`../../../Resources/Accessories/Accessory_${skin.accessoryId}.png`);

    importAll(imagesContextBody, findColorByCode(skin.bodyColor), null).then(setBodyImage).catch(console.error);
    importAll(imagesContextHair, findColorByCode(skin.hairColor), null).then(setHairImage).catch(console.error);
    importAll(imagesContextTop, findColorByCode(skin.topPrimaryColor), findColorByCode(skin.topSecondaryColor)).then(setTopImage).catch(console.error);
    importAll(imagesContextBottom, findColorByCode(skin.bottomPrimaryColor), findColorByCode(skin.bottomSecondaryColor)).then(setBottomImage).catch(console.error);
    importAll(imagesContextAccessories, findColorByCode(skin.accessoryPrimaryColor), findColorByCode(skin.accessorySecondaryColor)).then(setAccessoryImage).catch(console.error);
  }

  function parseSkinData(skinData) {
    const parts = skinData.split('-');

    // Body Data for Special Skins
    let bodyData = parts[0];
    let bodyColor, bodyId;

    if (bodyData.length < 4) {
      bodyId = bodyData; // if length is 3, get all digits as ID
      bodyColor = "";    // and set color as an empty string
    } else {
      bodyColor = bodyData.slice(-3); // get last 3 chars as color
      bodyId = bodyData.slice(0, -3); // get the remainder as ID
    }

    return {
      bodyId: bodyId,
      bodyColor: bodyColor,
      hairId: parts[1][0],
      hairColor: parts[1].substring(1),
      topId: parts[2][0],
      topPrimaryColor: parts[2].substring(1, 4),
      topSecondaryColor: parts[2].substring(4),
      bottomId: parts[3][0],
      bottomPrimaryColor: parts[3].substring(1, 4),
      bottomSecondaryColor: parts[3].substring(4),
      accessoryId: parts[4][0],
      accessoryPrimaryColor: parts[4].substring(1, 4),
      accessorySecondaryColor: parts[4].substring(4)
    };
  }

  const [colors] = useState([
    new UserFriendlyColor("white", "000"),
    new UserFriendlyColor("grey", "023"),
    new UserFriendlyColor("black", "016"),
    new UserFriendlyColor("lbrown", "019"),
    new UserFriendlyColor("brown", "020"),
    new UserFriendlyColor("dbrown", "021"),
    new UserFriendlyColor("yellow", "001"),
    new UserFriendlyColor("dyellow", "022"),
    new UserFriendlyColor("orange", "002"),
    new UserFriendlyColor("red", "003"),
    new UserFriendlyColor("pink", "013"),
    new UserFriendlyColor("purple", "011"),
    new UserFriendlyColor("violet", "010"),
    new UserFriendlyColor("lblue", "009"),
    new UserFriendlyColor("blue", "007"),
    new UserFriendlyColor("dblue", "008"),
    new UserFriendlyColor("teal", "014"),
    new UserFriendlyColor("lgreen", "017"),
    new UserFriendlyColor("green", "005"),
    new UserFriendlyColor("lgrey", "024"),
    new UserFriendlyColor("skin_0", "090"),
    new UserFriendlyColor("skin_1", "091"),
    new UserFriendlyColor("skin_2", "092"),
    new UserFriendlyColor("skin_3", "093")]);

  function findColorByCode(code) {
    const color = colors.find(color => color.gameCode === code);
    return color ? color.colorCode : null;
  }

  const createImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => {
        console.error(`Error occurred while loading image ${url}`, error);
        reject(error);
      };
      img.src = url;
    });
  };

  const scale = 10;
  const [animation] = useState('idle');
  const [index, setIndex] = useState(0);
  const idleAnimation = {
    idle: [0, 0, 32, 32,    // frame 1
      32, 0, 32, 32,   // frame 2
      64, 0, 32, 32,   // frame 3
      96, 0, 32, 32,   // frame 4
      128, 0, 32, 32,  // frame 5
      160, 0, 32, 32   // frame 6
    ]
  };

  function colorImage(imageElement, defaultPalette, colorPalette) {
    const canvas = document.createElement('canvas');
    canvas.width = imageElement.width;
    canvas.height = imageElement.height;

    const context = canvas.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.drawImage(imageElement, 0, 0);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    let data = imageData.data;

    let buffer = new Array(data.length); // Creating a new buffer

    for (let i = 0; i < data.length; i += 4) {
      let currentColor = `${data[i]}-${data[i + 1]}-${data[i + 2]}`;
      let colorIndex = defaultPalette.indexOf(currentColor);

      if (colorIndex !== -1) {
        let newColor = colorPalette[colorIndex];
        let colors = newColor.split("-");
        buffer[i] = colors[0];
        buffer[i + 1] = colors[1];
        buffer[i + 2] = colors[2];
        buffer[i + 3] = data[i + 3]; // Keep the alpha channel
      } else {
        // If there's no color change, copy from the existing data
        buffer[i] = data[i];
        buffer[i + 1] = data[i + 1];
        buffer[i + 2] = data[i + 2];
        buffer[i + 3] = data[i + 3];
      }
    }

    // apply buffer onto imageData
    for (let i = 0; i < data.length; i++) {
      data[i] = buffer[i];
    }

    context.putImageData(imageData, 0, 0);
    const coloredImage = new Image();
    coloredImage.src = canvas.toDataURL();
    return coloredImage;
  }

  useEffect(() => {
    applyColors();
    const interval = setInterval(() => {
      setIndex((index) => (index + 1) % 6);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const navigateWithHistory = url => {
    let savedUrls = sessionStorage.getItem('savedUrls');
    savedUrls = savedUrls ? JSON.parse(savedUrls) : [];
    savedUrls.push(window.location.pathname);
    sessionStorage.setItem('savedUrls', JSON.stringify(savedUrls));
    navigate(`/scoreboard/season-${userData.Season}/${url}`);
  }
  
  const onClickChangeSeason = () => {
    let season = userData.Season === 0 ? 1 : 0;
    const filePath = `/PlayerStats/season-${season}/char${userId.charAt(0)}.json`;
    fetch(filePath)
      .then(response => {
        if (!response.ok) {
          throw response
        }
        return response.json();
      })
      .then(data => {
          let user = data.find(user => user.PlayerName.toLowerCase() === userId.toLowerCase())
          if (user) {
            user.Season = season;
            user.Coins = userData.Coins;
            setUserData(user);
          } else {
            console.error("User s0 data not found.")
          }
        }
      )
      .catch(err => {
        console.error("User s0 data not found.")
      });
  };

  return (
    <main className={"userContainer"}>
      {userData && (
        <h1 style={{marginLeft: "64px", marginTop: "8px"}}>{userId.charAt(0).toUpperCase() + userId.slice(1).toLowerCase()}'s Instagram stats</h1>
      )}

      {colorsDict === undefined && <div style={{height: 32 * scale - 48}}></div>}
      {colorsDict !== undefined && <Stage style={{marginTop: "-32px", marginBottom: "-16px"}} width={32 * scale} height={32 * scale}>
        <Layer imageSmoothingEnabled={false}>
          {bodyImage && <Sprite
            image={bodyImage}
            animation={animation}
            animations={idleAnimation}
            frameRate={10}
            frameIndex={index}
            scaleX={scale}
            scaleY={scale}
          />}
          {bottomImage && <Sprite
            image={bottomImage}
            animation={animation}
            animations={idleAnimation}
            frameRate={10}
            frameIndex={index}
            scaleX={scale}
            scaleY={scale}
          />}
          {topImage && <Sprite
            image={topImage}
            animation={animation}
            animations={idleAnimation}
            frameRate={10}
            frameIndex={index}
            scaleX={scale}
            scaleY={scale}
          />}
          {hairImage && <Sprite
            image={hairImage}
            animation={animation}
            animations={idleAnimation}
            frameRate={10}
            frameIndex={index}
            scaleX={scale}
            scaleY={scale}
          />}
          {accessoryImage && <Sprite
            image={accessoryImage}
            animation={animation}
            animations={idleAnimation}
            frameRate={10}
            frameIndex={index}
            scaleX={scale}
            scaleY={scale}
          />}
        </Layer>
      </Stage>}

      <HomeButton url={'/user'} goBack={true}/>
      {userData && (
        <div style={{marginTop: "8px"}}>
          <h2 style={{marginTop: "0"}}>{userId.charAt(0).toUpperCase() + userId.slice(1).toLowerCase()}</h2>
          <p>Coins: {userData.Coins}</p>
          <div style={{display: "flex", alignItems: "center", marginBottom: "4px"}}>
            <h3 style={{margin: "0"}}>Season {userData.Season}</h3>
            {userData.Season > 0 &&
              (<span style={{marginLeft: "8px"}} className={"profileViewBtn"} onClick={onClickChangeSeason}>View Season 0</span>)
            }
            {userData.Season === 0 &&
              (<span style={{marginLeft: "8px"}} className={"profileViewBtn"} onClick={onClickChangeSeason}>View Season 1</span>)
            }
          </div>
          <p>Kills: {userData.Participations.reduce((sum, participation) => sum + participation["PlayerKills"], 0)}</p>
          <p>Participation Count: {userData.Participations.length}</p>
          <p>Wins: {userData.Participations.filter(participation => participation["IsWinner"] === true).length}</p>
          <p>First Blood Titles: {userData.Participations.filter(participation => participation["PlayerTitle"].includes("First Blood")).length}</p>
          <p>Slayer Titles: {userData.Participations.filter(participation => participation["PlayerTitle"].includes("Slayer")).length}</p>
          <p>Pacifist Titles: {userData.Participations.filter(participation => participation["PlayerTitle"].includes("Pacifist")).length}</p>
          <p>Bad Day Titles: {userData.Participations.filter(participation => participation["PlayerTitle"].includes("Bad Day")).length}</p>
        </div>)}
      {userData && (
        <div className={'userContainer'}>
          <h2>Battle History</h2>
          <h3>Season {userData.Season}</h3>
          <table>
            <thead>
            <tr>
              <th>Day</th>
              <th>Kills</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {userData.Participations.sort((a, b) => b.BattleId - a.BattleId).map((item, index) => (
              <tr key={index} className={item.KilledBy == "" ? "winnerRow" : null}>
              <td>{item.BattleId}</td>
                <td>{item.PlayerKills}</td>
                <td><span className={"profileViewBtn"} onClick={() => navigateWithHistory(item.BattleId)}>View Details</span></td>
              </tr>
            ))
            }
            </tbody>
          </table>
        </div>
      )}
    </main>
  )
    ;
};

export default UserPage;