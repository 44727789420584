let isAlreadyRegistered = false;

export function registerSW() {
    if ('serviceWorker' in navigator && !isAlreadyRegistered) {
        navigator.serviceWorker.register('/service-worker.js')
            .then(function (registration) {
                console.log('Service Worker registration successful with scope: ', registration.scope);
            })
            .catch(function (err) {
                console.log('Service Worker registration failed: ', err);
            });
        isAlreadyRegistered = true;
    }
}

export function unregisterSW() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}